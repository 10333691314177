import * as React from "react"
import { withStyles, Box, CircularProgress } from '@material-ui/core/';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import './hero.css';
import icon from '../images/fav.png'

const PreLoader = ({ classes, siteTitle, bannerData }) => (
  <div style={{ position: 'fixed', top: '0', width: '100%', background: '#fff', zIndex: '9999' }}>
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      {/* <CircularProgress color='secondary' size={50} /> */}
      <img src={icon} className='preloaderImage'/>
    </Box>
  </div>
)

const styles = (theme) => ({

})

export default withStyles(styles, { withTheme: true })(PreLoader)
