import * as React from "react"
import { Link } from "gatsby"
import { withStyles, IconButton, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grow, Paper, List, ListItemText, ListItem, ClickAwayListener } from '@material-ui/core/';
import { BiSearch } from 'react-icons/bi';
import { FiUser } from 'react-icons/fi';
import { AiOutlineHeart } from 'react-icons/ai';
import { FiShoppingCart } from 'react-icons/fi';
import { RiMenu3Line } from 'react-icons/ri';
import { useCartData, useAuthData } from '../helpers';
import LoginSignUpForm from './LoginSignUpForm';
import { VscClose } from 'react-icons/vsc';
import { BsPersonCheckFill } from 'react-icons/bs';
import { GoTriangleUp } from 'react-icons/go';

const HeaderActions = ({ classes, siteTitle, mobile, setOpenDrawer }) => {
  const [openLoginSignUp, setOpenLoginSignUp] = React.useState(false);
  const [openDropDown, setOpenDropDown] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('Login to your account');

  const cartData = useCartData();
  const authData = useAuthData();
  const cartCount = cartData?.cartCount;

  return (
    <>
      {!mobile ? <>
        <ul className={classes.root}>
          {/* <li><Link to='/'><BiSearch /></Link></li> */}
          {authData?.isLoggedIn ? <>
            <li onMouseOver={() => setOpenDropDown(true)} onMouseLeave={() => setOpenDropDown(false)}>
              <ClickAwayListener onClickAway={() => setOpenDropDown(false)}>
                <Link to='/user' >
                  {/* <BsCheckCircle className={classes.checkIcon} /> */}
                  <BsPersonCheckFill />
                </Link>
              </ClickAwayListener>
              <Grow
                className={`rightFilterDropdownMenu ${openDropDown && 'opened'}`}
                in={openDropDown}
                style={{ transformOrigin: '0 0 0' }}
                {...(openDropDown ? { timeout: 500 } : {})}
              >
                <Paper elevation={4} className={classes.paper}>
                  <GoTriangleUp style={{ position: 'absolute', color: '#fff', top: '-20px', right: '0', fontSize: '30px' }} />
                  <Box>
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem button onClick={() => authData?.logoutuser()} >
                        <ListItemText primary="Logout" />
                      </ListItem>
                      <ListItem button>
                        <ListItemText primary="Order History" />
                      </ListItem>
                    </List>
                  </Box>
                </Paper>
              </Grow>
            </li></> : <li onClick={() => setOpenLoginSignUp(true)}>
            <a><FiUser /></a>
          </li>}

          <li><Link to='/'><AiOutlineHeart /></Link></li>
          <li>
            <Link to='/cart'>
              <FiShoppingCart />
              {cartCount > 0 ? <span className={classes.cartCount}>{cartCount}</span> : ''}
            </Link>
          </li>
        </ul>
      </> : <>
        <Box display='flex' alignItems='center'>
          <IconButton style={{ color: '#000', fontSize: '18px' }}>
            <BiSearch />
          </IconButton>
          {authData?.isLoggedIn ? <><Link to='/user'>
            <IconButton style={{ color: '#000', fontSize: '18px' }}>
              {/* <BsCheckCircle className={classes.checkIcon} /> */}
              <BsPersonCheckFill />
            </IconButton>
          </Link></> : <IconButton style={{ color: '#000', fontSize: '18px' }} onClick={() => setOpenLoginSignUp(true)}>
            <FiUser />
          </IconButton>}
          <Box width='4px' />
          <Link to='/cart' style={{ position: 'relative' }}>
            <IconButton style={{ color: '#000', fontSize: '18px' }}>
              <FiShoppingCart />
            </IconButton>
            {cartCount > 0 ? <span className={classes.cartCount} style={{ top: '0', right: '0' }}>{cartCount}</span> : ''}
          </Link>
          <Box width='4px' />
          <IconButton style={{ color: '#000', fontSize: '18px' }} onClick={() => setOpenDrawer(true)}>
            <RiMenu3Line />
          </IconButton>
          <Box width='4px' />
        </Box>
      </>
      }

      <Dialog
        open={openLoginSignUp || authData?.openLoginSignUp}
        onClose={() => setOpenLoginSignUp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box paddin style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <DialogTitle id="alert-dialog-title" >
            {dialogTitle}
          </DialogTitle>
          <Box padding={1}>
            <IconButton onClick={() => { setOpenLoginSignUp(false); authData?.setOpenLoginSignUp(false) }}>
              <VscClose />
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <LoginSignUpForm setDialogTitle={setDialogTitle} setOpenLoginSignUp={setOpenLoginSignUp}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenLoginSignUp(false); authData?.setOpenLoginSignUp(false) }} color="secondary">
            Cancel
          </Button>
          {/* <Button onClick={() => setOpenLoginSignUp(false)} color="secondary" autoFocus>
            Create an account
          </Button> */}
        </DialogActions>
      </Dialog>

    </>

  )
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    listStyleType: 'none',
    margin: 0,
    width: '100%',

    '& li': {
      padding: '0 12px',
      margin: 0,
      position: 'relative',

      '& a': {
        fontSize: '24px'
      }
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  },
  cartCount: {
    position: 'absolute',
    fontSize: '12px',
    height: '20px',
    width: '20px',
    background: 'red',
    color: '#fff',
    borderRadius: '50%',
    top: '-8px',
    zIndex: '1',
    lineHeight: '20px',
    textAlign: 'center'
  },
  checkIcon: {
    position: 'absolute',
    top: '-4px',
    right: '-2px',
    color: 'green',
    fontSize: '18px'
  }
})

export default withStyles(styles, { withTheme: true })(HeaderActions)
