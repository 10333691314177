import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { withStyles, Hidden, IconButton, Box, Drawer, Container, InputBase, AppBar, Toolbar, Typography, Grid, LinearProgress } from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import Menu from './Menu'
import HeaderActions from './HeaderActions'
import { useAllProductsData } from '../helpers';
import { RiCloseLine } from 'react-icons/ri';
import { BiSearch } from 'react-icons/bi';

const Header = ({ classes, siteTitle }) => {
  const [scroll, setScroll] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const allProducts = useAllProductsData();

  const loading = allProducts?.mainSubCategoryDataLoading || allProducts?.productDetailsLoading || allProducts?.listOfProductsLoading;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 100)
    })
  }, [])


  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root} >
      <AppBar style={{ borderBottom: '1px solid #eee' }} position="fixed" color='primary' elevation={scroll ? 5 : 0}>
        <Hidden smDown>
          <Box className={classes.topBar}>
            <Grid container >
              <Grid className={classes.menuContent} item md={3} lg={3} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Link to='/'>
                  <Typography variant="h6" className={classes.title} style={{ color: '#fff' }}>
                    AmiZaya
                  </Typography>
                </Link>
              </Grid>
              <Grid className={classes.menuContent} item md={6} lg={6} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.search}>
                  <InputBase
                    placeholder="Search for products"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                  <div className={classes.searchIcon}>
                    <BiSearch style={{ fontSize: '24px', color: '#222' }} />
                  </div>
                </div>
              </Grid>
              <Grid item md={3} lg={3} xs={12} sm={12}>
                <Box display='flex' alignItems='center' justifyContent='flex-end' height='100%' style={{ color: '#fff' }} className={classes.loginSignUp}>
                  {/* <a>Sign Up</a>
                  <Box width='24px' display='flex' justifyContent='center'>|</Box>
                  <a>Login</a> */}
                  <HeaderActions />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>
        <Toolbar style={{ height: '64px' }} style={{ background: '#fff' }}>
          {/* <Hidden smDown>
            <Grid container >
              <Grid className={classes.menuContent} item md={3} lg={3} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Link to='/'>
                  <Typography variant="h6" className={classes.title}>
                    AmiZaya
                  </Typography>
                </Link>
              </Grid>
              <Grid className={classes.menuContent} item md={6} lg={6} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Menu allProducts={allProducts} />
              </Grid>
              <Grid className={classes.menuContent} item md={3} lg={3} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <HeaderActions />
              </Grid>
            </Grid>
          </Hidden> */}
          <Hidden smDown>
            <Container >
              <Menu allProducts={allProducts} />
              {/* <Grid className={classes.menuContent} item md={3} lg={3} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}> */}
              {/* <HeaderActions /> */}
              {/* </Grid> */}
            </Container>
          </Hidden>
          <Hidden smUp>
            <Box display='flex' alignItems='center' justifyContent='space-between' style={{ width: '100%' }}>
              <Link to='/'>
                <Typography variant="h6" className={classes.title}>
                  AmiZaya
                </Typography>
              </Link>
              <HeaderActions mobile setOpenDrawer={setOpenDrawer} />
            </Box>
          </Hidden>
          <Drawer className='mobileDrawer' anchor='bottom' open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Box onClick={() => setOpenDrawer(false)} style={{ position: 'fixed', left: '45vw', top: '26vh' }}><RiCloseLine style={{ color: '#fff', fontSize: '30px', opacity: '0.7' }} /></Box>
            <Menu allProducts={allProducts} mobileView setOpenDrawer={setOpenDrawer} />
          </Drawer>
        </Toolbar>
        {loading && <LinearProgress variant="determinate" value={progress} color='secondary' />}
      </AppBar>
    </div >
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `AmiZaya`,
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuContent: {

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    color: '#000'
  },
  topBar: {
    // background: '#0E223A',
    background: '#222',
    padding: '16px 24px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    // opacity: '0.15',
    '&:hover': {
      backgroundColor: '#fff',
      // opacity: '0.25'
    },
    marginRight: theme.spacing(2),
    color: '#222',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '100%',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    right: '0'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    height: '48px'
  },
  inputInput: {
    padding: '8px 24px',
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      // width: '20ch',
    },
  },
  loginSignUp: {
    color: '#fff',
    '& a': {
      color: '#fff',
      cursor: 'pointer'
    }
  }
})

export default withStyles(styles, { withTheme: true })(Header)
