/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import "./layout.css";
import { useLocation } from "@reach/router";

const Layout = ({ children, loading }) => {
  const location = useLocation();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  console.log('location', location)

  const hideHeaderFooter = location.pathname.includes('checkout') || location.pathname.includes('result');

  return (
    <>
      {!hideHeaderFooter && <Header siteTitle={data.site.siteMetadata?.title || `Title`} />}
      {loading ? <PreLoader /> : <div>
        <main>{children}</main>
        {/* <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer> */}
        {!hideHeaderFooter && <Footer />}
      </div>}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
