import * as React from "react";
import { withStyles, TextField, Button, Box } from '@material-ui/core/';
import { ChevronLeft, ChevronRight, ClearAll } from '@material-ui/icons';
import { useAuthData } from '../helpers';
import { useSnackbar } from 'notistack';

const LoginSignUpForm = ({ classes, setDialogTitle, setOpenLoginSignUp }) => {
  const [loginForm, setLoginForm] = React.useState(true);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('91');
  const [userName, setUserName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [middleName, setMiddleName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const authData = useAuthData();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleLoginFormSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      Mobilenumber: phoneNumber,
      Password: password,
      // Countrycode: countryCode,
      Username: userName,
    }
    console.log('payload', payload);
    await authData?.fetchLogin(payload);
    setOpenLoginSignUp(false);
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      mobileNumber: parseInt(phoneNumber),
      countryCode: parseInt(countryCode),
      userName: userName,
      emailAddress: email,
      password: password,
      has2FA: false,
      requestType: '',
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      loggedInUserId: 0
    }
    console.log('payload', payload);
    const response = await authData?.fetchLoginRegister(payload);
    console.log('response', response);
    if (response && response.ok) {
      if (response?.data && response?.data?.find(l => l.Status.toLowerCase() === 'success' || l.status.toLowerCase() === 'success')) {
        enqueueSnackbar({ message: 'Registered Successfully!', variant: 'success' }, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        setTimeout(() => {
          setLoginForm(true);
        }, 1000);
      }
    } else {
      enqueueSnackbar({ message: 'Failed to register!', variant: 'error' }, {
        // variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  }

  const clearAll = () => {
    setCountryCode('');
    setPhoneNumber('');
    setUserName('');
    setPassword('');
    setEmail('');
    setFirstName('');
    setMiddleName('');
    setLastName('');
  }

  return (
    <div>
      {loginForm && <>
        <form className={classes.loginForm} onSubmit={(e) => handleLoginFormSubmit(e)}>
          <Box paddingY={1} display='flex' alignItems='center' justifyContent='space-between'>
            <Box width='100px' paddingRight={1}>
              <TextField id="countryCode" label="Code" variant="outlined" fullWidth onChange={(e) => setCountryCode(e.target.value)} />
            </Box>
            <Box width='100%'>
              <TextField id="Phone" required label="Phone Number" variant="outlined" fullWidth onChange={(e) => setPhoneNumber(e.target.value)} />
            </Box>
          </Box>
          <Box paddingY={1}>
            <TextField id="userName" label="User Name" variant="outlined" fullWidth onChange={(e) => setUserName(e.target.value)} />
          </Box>
          <Box paddingY={1}>
            <TextField id="password" type='password' required label="Password" variant="outlined" fullWidth onChange={(e) => setPassword(e.target.value)} />
          </Box>
          <Box height={2} />
          <Box paddingY={1} display='flex' alignItems='center' justifyContent='space-between'>
            <Button type='submit' variant='contained' color='secondary' disabled={!phoneNumber || !password}>Login</Button>
            <a style={{ cursor: 'pointer' }} onClick={() => { setLoginForm(false); setDialogTitle('Create Account'); clearAll(); }}>Create an account</a>
          </Box>
        </form>
      </>}
      {!loginForm && <>
        <form className={classes.loginForm} onSubmit={(e) => handleFormSubmit(e)}>
          <Box paddingY={1} display='flex' alignItems='center' justifyContent='space-between'>
            <Box width='100px' paddingRight={0.5}>
              <TextField id="countryCode" label="Code" variant="outlined" fullWidth onChange={(e) => setCountryCode(e.target.value)} />
            </Box>
            <Box width='100%'>
              <TextField id="Phone" required label="Phone Number" variant="outlined" fullWidth onChange={(e) => setPhoneNumber(e.target.value)} />
            </Box>
          </Box>
          <Box paddingY={1}>
            <TextField id="email" label="Email" variant="outlined" fullWidth onChange={(e) => setEmail(e.target.value)} />
          </Box>
          <Box paddingY={1}>
            <TextField id="firstName" label="First Name" variant="outlined" fullWidth onChange={(e) => setFirstName(e.target.value)} />
          </Box>
          <Box paddingY={1}>
            <TextField id="middleName" label="Middle Name" variant="outlined" fullWidth onChange={(e) => setMiddleName(e.target.value)} />
          </Box>
          <Box paddingY={1}>
            <TextField id="lastName" label="Last Name" variant="outlined" fullWidth onChange={(e) => setLastName(e.target.value)} />
          </Box>
          <Box paddingY={1}>
            <TextField id="userName" label="User Name" variant="outlined" fullWidth onChange={(e) => setUserName(e.target.value)} />
          </Box>
          <Box paddingY={1}>
            <TextField id="Password" type='password' required label="Password" variant="outlined" fullWidth onChange={(e) => setPassword(e.target.value)} />
          </Box>
          <Box height={2} />
          <Box paddingY={1} display='flex' alignItems='center' justifyContent='space-between'>
            <Button type='submit' variant='contained' color='secondary' disabled={!phoneNumber || !password}>Submit</Button>
            <a style={{ cursor: 'pointer' }} onClick={() => { setLoginForm(true); setDialogTitle('Login to your account'); clearAll(); }}>Already have an account ?</a>
          </Box>
        </form>
      </>}
    </div >
  )
}

const styles = (theme) => ({
  loginForm: {
    width: '25vw',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  }
})

export default withStyles(styles, { withTheme: true })(LoginSignUpForm)
