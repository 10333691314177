import * as React from "react";
import { withStyles, Container, Grid, Typography, Box, Input, InputAdornment, Button, FormControl, OutlinedInput } from '@material-ui/core/';
import { Link } from "gatsby";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaCcVisa } from 'react-icons/fa';
import { FaCcPaypal } from 'react-icons/fa';
import { FaCcMastercard } from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';

import './footerStyles.css';

const Footer = ({ classes }) => (
  <>
    <Box paddingY={8} className='footer'>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={3} lg={3} xs={12} sm={12}>
            <Link to='/'>
              <Typography variant="h6" className={classes.title}>
                {/* {siteTitle} */}AmiZaya
              </Typography>
            </Link>
            <Box>
              <ul>
                <li>
                  <i><FaMapMarkerAlt /></i>
                  <Box width='16px' />
                  <span>184 Main Rd E, St Albans VIC 3021 Australia</span>
                </li>
                <li>
                  <i><FaEnvelope /></i>
                  <Box width='16px' />
                  <span>Contact@company.com</span>
                </li>
                <li>
                  <i><FiPhone /></i>
                  <Box width='16px' />
                  <span>+001223445</span>
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid item md={9} lg={9} xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item md={3} lg={3} xs={12} sm={12}>
                <strong>Categories</strong>
                <ul>
                  <li>
                    <Link to=''>Men</Link>
                  </li>
                  <li>
                    <Link to=''>Women</Link>
                  </li>
                  <li>
                    <Link to=''>Accessories</Link>
                  </li>
                  <li>
                    <Link to=''>Shoes</Link>
                  </li>
                  <li>
                    <Link to=''>Denim</Link>
                  </li>
                </ul>
              </Grid>
              <Grid item md={3} lg={3} xs={12} sm={12}>
                <strong>Information</strong>
                <ul>
                  <li>
                    <Link to=''>About Us</Link>
                  </li>
                  <li>
                    <Link to=''>Contact Us</Link>
                  </li>
                  <li>
                    <Link to=''>Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to=''>Returns & Exchange</Link>
                  </li>
                  <li>
                    <Link to=''>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to=''>Store Location</Link>
                  </li>
                  <li>
                    <Link to=''>My Account</Link>
                  </li>
                  <li>
                    <Link to=''>FAQs</Link>
                  </li>
                </ul>
              </Grid>
              <Grid item md={6} lg={6} xs={12} sm={12}>
                <strong>Newsletter Signup</strong>
                <p><small>Subscribe to our newsletter and get 10% off.</small></p>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <OutlinedInput
                    id="standard-adornment-password"
                    type='text'
                    // value={values.password}
                    // onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          aria-label="toggle password visibility"
                          // onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          variant='contained'
                          size='small'
                          color='secondary'
                        >
                          Subscribe
                        </Button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Box className='paymentIcons' paddingTop={2}>
                  <i>
                    <FaCcVisa />
                  </i>
                  <Box width='16px' />
                  <i>
                    <FaCcPaypal />
                  </i>
                  <Box width='16px' />
                  <i>
                    <FaCcMastercard />
                  </i>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <footer>
      Copyright © 2021 Amizaya all rights reserved.
    </footer>
  </>
)

const styles = (theme) => ({
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    color: '#000'
  },
})

export default withStyles(styles, { withTheme: true })(Footer)
