import * as React from "react"
import { Link, navigate } from "gatsby"
import {
  withStyles, Grid, Box, Container, Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grow,
  Paper
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Menu = ({ classes, siteTitle, allProducts, mobileView, setOpenDrawer }) => {
  const [isShopDropdownVisible, setIsShopDropdownVisible] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const hovered = (menuId) => {
    setIsShopDropdownVisible(menuId);
  }

  const handleClickCategory = (categoryItem, item) => {
    if (categoryItem?.SubCategory?.length === 0) {
      navigate(`/products/${categoryItem.CategoryId}/`);
    } else {
      navigate(`/categories/${categoryItem.CategoryId}?menu=${item.MenuId}`);
    }
    if (mobileView) {
      setOpenDrawer(false);
    }
  }
  const hideDropdown = () => {
    setIsShopDropdownVisible(false);
    if (mobileView) {
      setOpenDrawer(false);
    }
  }
  const largeMenu = allProducts?.mainSubCategoryData.slice(0, 6)
  return (
    <>
      {mobileView && <>
        <Box className='bigDropDownContent mobileMenu' padding={0}>
          {/* {allProducts?.mainSubCategoryData?.map((item, index) => (
            <Grid item md={3} lg={3} xs={12} sm={12} key={item.CategoryId}>
              <Link onClick={() => setOpenDrawer(false)} to={`/categories/${item.CategoryId}`}><strong>{item.Category}</strong></Link>
              <ul>
                {item?.SubCategory?.map((subItem, subIndex) => (
                  <li key={subItem.SubCategoryId}>
                    <Link onClick={() => setOpenDrawer(false)} to={`/products/${subItem.SubCategoryId}?category=${item.CategoryId}`}>{subItem.SubCategory}</Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))} */}
          {allProducts?.mainSubCategoryData?.map((item, index) => (
            <Accordion expanded={expanded === item.MenuId} onChange={handleChange(item.MenuId)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>{item.MenuName}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                {item?.Category.length === 0 ? <>No Products Aavailable</> : <>
                  {item?.Category?.map((categoryItem, categoryIndex) => (
                    <Box key={categoryIndex} style={{ width: '100%' }}>
                      {/* <Link onClick={hideDropdown} to={`/categories/${categoryItem.CategoryId}?menu=${item.MenuId}`}><strong>{categoryItem.Category}</strong></Link> */}
                      <a style={{ cursor: 'pointer' }} onClick={() => handleClickCategory(categoryItem, item)} ><strong>{categoryItem.Category}</strong></a>
                      <ul>
                        {categoryItem?.SubCategory?.map((subItem, subIndex) => (
                          <li key={subItem.SubCategoryId}>
                            <Link onClick={hideDropdown} to={`/products/${categoryItem.CategoryId}/${subItem.SubCategoryId}/`}>{subItem.SubCategory}</Link>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ))}
                </>}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </>}
      {!mobileView && <>
        <ul className={classes.root}>
          {largeMenu?.map((item, index) => (
            <li onMouseOver={() => hovered(item.MenuId)} onMouseLeave={() => hideDropdown()} key={item.MenuId}>
              {/* <span style={{ background: '#01BAD4' }}>New</span> */}
              <Link onClick={hideDropdown} to={`/categories/${item.MenuId}`} style={{ display: 'flex', alignItems: 'center' }}>
                {item.MenuName}
                <Box width='4px' />
                {isShopDropdownVisible === item.MenuId ? <ExpandLessIcon style={{ fontSize: '20px' }} /> : <ExpandMoreIcon style={{ fontSize: '20px' }} />}
              </Link>
              <Grow
                className={`bigDropDown ${isShopDropdownVisible === item.MenuId && 'opened'}`}
                in={isShopDropdownVisible === item.MenuId}
                style={{ transformOrigin: '0 0 0' }}
                {...(isShopDropdownVisible === item.MenuId ? { timeout: 500 } : {})}
              >
                {/* <Box className=''> */}
                <Paper elevation={0} className={classes.paper}>
                  <Box className='bigDropDownContent' style={{ borderTop: '1px solid #eee', boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%)' }}>
                    <Container>
                      <Grid container spacing={2}>
                        {item?.Category.length === 0 ? <>No Products Aavailable</> : <>
                          {item?.Category?.map((categoryItem, categoryIndex) => (
                            <Grid item md={3} lg={3} xs={12} sm={12} key={categoryIndex}>
                              <a style={{ cursor: 'pointer' }} onClick={() => handleClickCategory(categoryItem, item)} ><strong>{categoryItem.Category}</strong></a>
                              <ul>
                                {categoryItem?.SubCategory?.map((subItem, subIndex) => (
                                  <li key={subItem.SubCategoryId}>
                                    {/* <Link onClick={hideDropdown} to={`/products/${subItem.SubCategoryId}?category=${categoryItem.CategoryId}`}>{subItem.SubCategory}</Link> */}
                                    <Link onClick={hideDropdown} to={`/products/${categoryItem.CategoryId}/${subItem.SubCategoryId}/`}>{subItem.SubCategory}</Link>
                                  </li>
                                ))}
                              </ul>
                            </Grid>
                          ))}
                        </>}
                      </Grid>
                    </Container>
                  </Box>
                </Paper>
                {/* </Box> */}
              </Grow>
              {/* {isShopDropdownVisible === item.MenuId &&
           
              } */}
            </li>
          ))}
          <li onMouseOver={() => hovered('all')} onMouseLeave={() => hideDropdown()}>
            {/* <span style={{ background: '#01BAD4' }}>New</span> */}
            <a onClick={hideDropdown} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              Show All
              <Box width='4px' />
              {isShopDropdownVisible === 'all' ? <ExpandLessIcon style={{ fontSize: '20px' }} /> : <ExpandMoreIcon style={{ fontSize: '20px' }} />}
            </a>
            <Grow
              className={`bigDropDown ${isShopDropdownVisible === 'all' && 'opened'}`}
              in={isShopDropdownVisible === 'all'}
              style={{ transformOrigin: '0 0 0' }}
              {...(isShopDropdownVisible === 'all' ? { timeout: 500 } : {})}
            >
              {/* <Box className=''> */}
              <Paper elevation={0} className={classes.paper}>
                <Box className='bigDropDownContent' style={{ borderTop: '1px solid #eee', boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%)' }}>
                  <Container>
                    <Grid container spacing={2}>
                      {allProducts?.mainSubCategoryData?.map((item, categoryIndex) => (
                        <Grid item md={3} lg={3} xs={12} sm={12} key={categoryIndex}>
                          <Link onClick={hideDropdown} to={`/categories/${item.MenuId}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <strong>{item.MenuName}</strong>
                            {/* <Box width='4px' /> */}
                            {/* {isShopDropdownVisible === item.MenuId ? <ExpandLessIcon style={{ fontSize: '20px' }} /> : <ExpandMoreIcon style={{ fontSize: '20px' }} />} */}
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Container>
                </Box>
              </Paper>
              {/* </Box> */}
            </Grow>
          </li>
          {/* <li>
            <Link to='/'>Company</Link>
          </li>
          <li>
            <span style={{ background: '#FFA800' }}>New</span>
            <Link to='/'>Sale</Link>
          </li>
          <li>
            <Link to='/'>Blog</Link>
          </li> */}
        </ul>
      </>}
    </>
  )
}


const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyleType: 'none',
    width: '100%',
    margin: 0,
    '& > li': {
      padding: '0',
      margin: 0,
      height: '64px',
      lineHeight: '64px',
      position: 'relative',
      '& .bigDropDown': {
        width: '100%',
        position: 'fixed',
        // display: 'none',
        left: '0',
      },
      '& span': {
        display: 'block',
        position: 'absolute',
        height: '5px',
        fontSize: '12px',
        background: '#000',
        color: '#fff',
        top: '12px',
        right: '-7px',
        width: '45px',
        textAlign: 'center',
        height: '19px',
        lineHeight: '18px',
        borderRadius: '25px'
      },
      // '&:hover': {
      //   '& .bigDropDown': {
      //     display: 'block',
      //     opacity: 1,
      //   }
      // }
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  },
})

export default withStyles(styles, { withTheme: true })(Menu)
